<template>

<v-container class="down-top-padding" fluid>
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>

    <v-row>
      <v-col cols="12">

        <v-toolbar dark prominent id="toolbar">
          <v-toolbar-title id="toolbarTitle">E-Commerce</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-data-table id="dataTable"
        v-model="selected"
        :headers="headers"
        :item-key="main_key"
        :items="rows"
        :items-per-page="50"
        :search="search"
        :single-select="singleSelect"
        class="elevation-1"
        selectable-key="selected"
        show-select
    ></v-data-table>

</v-container>

</template>

<script>
import {db, functions} from "@/firebase";
export default {
    name: 'PRODUCTS',

    data: () => ({
        page: {
            title: 'Products'
        },
        breadcrumbs: [
            {
                text: "Products",
                disabled: true
            }
        ],
        pharmacy_id: '',
        rows: [],
        headers: [
          {
            text: 'ID PRODOTTO',
            value: 'product_id',
          },
          {
            text: 'CODICE ARTICOLO',
            value: 'product_code',
          },
          {
            text: 'DESCRIZIONE ARTICOLO',
            value: 'product',
          },
          {
            text: 'GIACENZA',
            value: 'amount',
          },
          {
            text: 'MAIN CATEGORY',
            value: 'main_category',
          },
          {
            text: 'PREZZO',
            value: 'price',
          }
        ],
        main_key: 'product_id',
        search: '',
        selected: [],
        singleSelect: true,

    }),

    methods: {

        get_pharmacy_id() {

            db.collection('farmacie')
            .doc('e4a90f9c-8204-46e3-90f0-4242d1c45cd1')
            .get().then(snapshot => {

                if(snapshot.empty) {
                    return console.log("Error on the snapshot.")
                }

                this.pharmacy_id = snapshot.data().id
                this.change_table_source('cs_products')


            }).catch(error => {
                console.log(error.toLocaleString)
            })

        },

        change_table_source(collection) {
            this.rows = [];
            console.log(this.pharmacy_id)
            let addMessage = functions.httpsCallable('get_collection');

            addMessage({
                collection: collection,
                pharma_id: this.pharmacy_id
            })
            .then(function (result) {
                result.data.forEach(doc => {
                    this.rows.push(doc);
            });
            console.log(this.rows)

            }.bind(this))
            .catch(error => {
                console.log("error on change_table_source method", error)
          })

        },

    },


    mounted() {
        this.get_pharmacy_id()
    },
}
</script>

<style lang="scss" scoped>

    v-row {
        width: 100%;
    }

    #toolbar {
        height: 80px !important;
    }

    #toolbarTitle {
        height: 100px !important;
    }

    #dataTable {
        padding-top: 10px;
    }
</style>
